<template>
    <div class="journals-page">
        <Header :doc="doc"/>
        <div class="container">

            <div class="row mb-3">
                <div class="col-md-8 content">
                    <component :is="hrefs('/journals').content" />
                </div>
                <div class="col-md-4 contacts">
                    <component :is="hrefs('/contacts').content" />
                </div>
                
            </div>

            
        </div>
        <JournalsList />
    </div>
</template>

<script>
import NewsList from '../components/NewsList'
import JournalsList from '../components/JournalsList.vue';
import { mapGetters } from 'vuex'

export default {
    name: 'Journals',
    components: {
        NewsList,
        JournalsList
    },
    data: () => ({
        
    }),
    computed: {
        journals() {
            return [].concat(this.hrefs(`/journals/*`)).sort( (a,b) => a.meta.order - b.meta.order)
        },
        doc() {
            return this.hrefs('/journals')
        },
        issn() {
            return this.$route.params.issn
        },
        journalDoc() {
            this.hrefs(`/journals/${this.issn}`)
        },
        journal() {
            return this.journalsList.find(j => j.issn == this.issn)
        },
        ... mapGetters(['journalsList'])
    }
}
</script>

<style lang="scss" scoped>
img.card-img-top {
    width: 50%;
    height: auto;
    display: block;
    margin-left: auto;
    margin-right: auto;
    max-height: 300px;
}
.card-issn {
    text-align: center;
}
.contacts {
    /deep/ img {
        max-width: 300px;
    }
}

.router-view {
    .issues-view-wrapper > div {
        display: flex;
        // /deep/ .row {
        //     display: inline;
        // }
        /deep/ .card-header {
            display: none;
        }
        /deep/ .card {
            border: none;
        }
        .btn {
            margin: auto 30%;
        }
    }
    .cta {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
    }
}
</style>
<template>
    <div>
        <Small-Header :doc="doc" v-if="!$route.name"/>
        <div class="container">
            <div class="row my-3">
                <div class="col-lg-3 justify-content-center" v-if="!$route.name">
                    <img v-if="journal && journal.issues.length && journal.issues[0].cover" class="journal-image mx-auto d-block"  :src="journal.issues[0].cover" :alt="doc.meta.title">
                    <img v-else class="journal-image mx-auto d-block" :src="doc.meta.image" :alt="doc.meta.title">
                </div>
                <div class="col-lg-9">
                    <component :is="doc.content" v-if="!$route.name" />
                </div>
            </div>
            <div class="row my-3">
                <div class="col-lg-12">
                    <ul class="nav nav-pills justify-content-end">
                        <li class="nav-item text-right" v-for="item in meta.meta.links" :key="`details-link-${item.type}`">
                            <router-link
                                class="nav-link"
                                :class="{active: $route.query.href == `${doc.meta.href}/${item.type}` }"
                                :to="{path: `/journal/${issn}/details`, query: { href: `${doc.meta.href}/${item.type}` } }"
                            >
                                {{item.name}} »
                            </router-link>
                        </li>
                    </ul>
                </div>
            </div>

            <div class="row search-row">

                <div class="col-lg-4">
                    <router-view name="category" v-if="journal" :journal="journal" :doc="doc" :meta="meta"></router-view>
                    <br>
                    <router-view name="filter" v-if="journal" :journal="journal" :doc="doc" :meta="meta"></router-view>
                </div>

                <div class="col-lg-8 content">
                    <div class="card text-black  bg-light mb-3">
                        <router-view  v-if="journal" :journal="journal" :doc="doc" :meta="meta"></router-view>
                    </div>
                </div>

            </div>

        </div>

    </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
    name: 'Journal',
    data: () => ({
        selectedCard: null
    }),
    mounted() {
        console.log('mounted')
        // if(this.issn && !this.journal)
        //     this.$router.go(-1)
    },
    computed: {
        issn() {
            return this.$route.params.issn
        },
        journal() {
            return this.journalsList.find(j => j.issn == this.issn)
        },
        doc() {
            return this.hrefs('/journals/'+this.issn)
        },
        meta() {
            return this.hrefs('/journal/index')
        },
        ... mapGetters(['journalsList'])
    }
}
</script>

<style lang="scss" scoped>
.content {
    min-height: 50vh;
}
.journal-image {
    height: 250px;
}
.list-item {
    height: 200px;
    img {
        max-height: 180px;
        max-width: 150px;
        width: auto;
        height: auto;
        display: block;
        margin:auto;
        vertical-align: middle;
    }
}
</style>